
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home'; // Tuodaan juuri luomamme Home-komponentti
import Interview from './Haastettelu'; // Tuodaan myös Harrastukset-komponentti

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />           {/* Pääsivu */}
      <Route path="/interview" element={<Interview />} /> {/* Harrastukset-sivu */}
    </Routes>
  );
}

export default App;
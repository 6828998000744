import './App.css';
import { useEffect, useState, useRef } from 'react'
import Form from "./form2";
import Simulator from "./Simulator";
import Modal from 'react-modal';
import Flag from 'react-world-flags';
import ChooseForm from "./testiform2";
import Logopikku from "./logo.png";
import AI from "./AI.jpg";
import Nav from "./navbar3";

function Haastattelu() {

  const [language, setLanguage] = useState("en-US");
  const [isOpen, setIsOpen] = useState(true);
  const [authentication, setAuthentication] = useState(false);
  const [form, setForms] = useState(false);
  const [flag, setFlags] = useState(false);
  const [kredit, setKredit] = useState(-1);
  const [finland, setFinland] = useState(false)

  //valinnat
  const [selectedOptions, setSelectedOptions] = useState({});
 
  function loadKredits(kredit) {
    setKredit(kredit)
  }

  const handleCloseModal = () => {
    setIsOpen(false);
  }

  function changeLanguage(lan) {
    setLanguage(lan)
    setFlags(false);
    setForms(true);
  }

  function closeOpen( freeText, selectedOption, searchText,freeText2 ) {
    setForms(false);
    setIsOpen(false);

    setSelectedOptions(prevState => ({
      ...prevState,
      level: selectedOption,
      industry: searchText,
      position: freeText,
      url: freeText2,   
    }));
  }

  function changeAuthentication() {
    setAuthentication(true);
    setFlags(true);
  }
  

  useEffect(() => {

    const storedEmail = sessionStorage.getItem('email');
    const withGoogle = sessionStorage.getItem('withGoogle');
    if (storedEmail ) {
      setAuthentication(true);
      setFlags(true);
    } 
    const domain = window.location.hostname;
    console.log(domain);
    if (domain === 'theinterviewsimulator.com' || domain === 'www.theinterviewsimulator.com') {
      setFinland(false);
      
    } else {
      setFinland(true);
    }
    console.log(finland);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    

    // Komponentin poistuessa, poista tapahtumankäsittelijä
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const myRef = useRef();

      useEffect(() => {
        myRef.current.focus();
  }, [flag]);

  
  return (
    <div className="Front" ref={myRef}>     
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={handleCloseModal}
        contentLabel="Esimerkki modaali"
        shouldCloseOnOverlayClick={false}
        className={form ? "ChoiceModal" : "AppModal"}
        overlayClassName="AppModalOverlay">
        {flag &&<div style={{textAlign:"center", paddingTop:"2vh"}}><h2>Choose language</h2></div>}
        {flag &&
        <div className='Flags'>  
          <span onClick={() => changeLanguage("en-US")} className='Flag'><Flag code="US" className='FlagId'  /></span>
          <span onClick={() => changeLanguage("es-ES")} className='Flag'><Flag code="esp" className='FlagId' /></span>
          <span onClick={() => changeLanguage("fi")} className='Flag' ><Flag code="fin" className='FlagId'  /></span>
        </div>}
        {form && <ChooseForm function={closeOpen} lan={language}/>}
        {!authentication && <Form function={changeAuthentication} loadkredit={loadKredits} />}
      </Modal> 
      {isOpen && <div><Nav lan={finland} /></div>}
      {isOpen && <div><img src={AI} className='AI' alt="The Interview Simulator"/></div>}
      {isOpen && <div><img src={Logopikku} className='LomakeLogo' alt="Lassi Albin"></img></div>}
      {isOpen && <div><h1 className='LomakeTitle'>The Interview Simulator</h1></div>} 
      {!isOpen && <Simulator selectedoptions={selectedOptions}kre={kredit}lan={language} loadkredit={loadKredits} />}  
      {isOpen && <div><h1 className='Footer'>© 2024 LASSI ALBIN. ALL RIGHTS RESERVED.</h1></div>}  
      
    </div>
  );}
  
export default Haastattelu;
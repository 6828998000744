import { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import './App.css';
import { signInWithGoogle, handleGoogleSignInRedirect } from './googleSignIn';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Logopikku from "./logopikku.png";
import Logo from "./logo.png";


function AuthenticationForm2(props) {
    const [isLoginForm, setIsLoginForm] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [finland, setFinland] = useState(false)
    const [titles, setTitles] = useState({signin: "Sign in",
    signup: "Sign up",
    forgot: "Forgot your password?",
    email: "Email",
    password:"Password",
    update: "Password update",
    placeemail: "Your email",
    yourpass: "Your password",
    createpass: "Create password",
    submit: "submit",
    new: "New password",
    loading: "Loading..."})

    const titlesFi = {
      signin: "Kirjaudu sisään",
        signup: "Rekisteröidy",
        forgot: "Unohditko salasanasi?",
        email: "Sähköposti",
        password:"Salasana",
        update: "Salasanan uusiminen",
        placeemail: "Sinun sähköpostiosoite",
        yourpass: "Sinun salasanasi",
        createpass: "Luo salasana",
        submit: "Lähetä",
        new: "Uusi salasana",
        loading: "Hetkinen...",
        id : "Käyttäjätunnus",
        id2 : "Kirjaudu sisään käyttäjätunnuksella"
      }

      const titlesEng = {
        signin: "Sign in",
        signup: "Sign up",
        forgot: "Forgot your password?",
        email: "Email",
        password:"Password",
        update: "Password update",
        placeemail: "Your email",
        yourpass: "Your password",
        createpass: "Create password",
        submit: "submit",
        new: "New password",
        loading: "Loading...",
        id : "USER ID",
        id2 : "Use your user ID to log in"
        }

      

    const [info, setInfo] = useState('');
    const [info2, setInfo2] = useState('');

    useEffect(() => {
      
      if (window.location.search.includes('?signin')) {
        setIsLoginForm(false);
      } else {
        setIsLoginForm(true);
      }
   
    }, []);

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSend(e);
      }
      else if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation(); 
        // Voit myös tehdä muita toimintoja tässä, jos haluat
      }
    };
    
      const handleSend = async (event) => {


        // Jos kaikki tarkistukset menevät läpi, tyhjennä info ja jatka lomakkeen lähettämistä
        setInfo("");
        setInfo2("");

        event.preventDefault();
        /*
        setIsLoading(true);
          //fetch('https://us-central1-simudatauser.cloudfunctions.net/api/haastatteluIn', {
            fetch('http://127.0.0.1:5001/simudatauser/us-central1/api/haastatteluIn', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: email, pass:password, login:isLoginForm, lan:finland})
        })
  
        .then(response => response.json())
        .then(data => { 
      
            setIsLoading(false);
            
            if (data.status == "tryout" || data.status == "member" ){
 
              sessionStorage.setItem('email', email);
              props.loadkredit(data.count);
              props.function();
              
            }else if (data.status == "noactive"){
              if(!finland){
              setInfo("To activate your account, please click the activation link that we've sent to your email. Once you've clicked the link, your account will be fully active and ready to use.");
              setInfo2("Check your inbox for activation link!"); 
              }else{
                setInfo("Aktivoidaksesi tilisi, klikkaa aktivointilinkkiä, jonka olemme lähettäneet sähköpostiisi. Kun olet klikannut linkkiä, tilisi on aktiivinen ja käyttövalmis."); 
                setInfo2("Klikkaa aktivointilinkkiä sähköpostissasi!"); 
              }
            }
            else if (data.status == "wrongemail"){
              if(!finland){
              setInfo("Please check your email address or register if you don't have an account yet"); 
              setInfo2("Please check your email address or register if you don't have an account yet"); 
              }else{
                setInfo("Tarkista sähköpostiosoitteesi tai rekisteröidy, jos sinulla ei vielä ole tiliä"); 
                setInfo2("Tarkista sähköpostiosoitteesi tai rekisteröidy, jos sinulla ei vielä ole tiliä"); 
              }
            }    
            else if (data.status == "password"){
              if(!finland){
              setInfo("Password incorrect!");
              setInfo2("Password incorrect!");
              }else {
                setInfo("Väärä salasana!");
                setInfo2("Väärä salasana!");
              }
            }
            else if (data.status == "passUpdated"){
              if(!finland){
              setInfo("Before you can log in, you need to activate a new password from the link we sent to your email.");
              setInfo2("Before you can log in, you need to activate a new password from the link we sent to your email.");
              }else {
                setInfo("Ennen kuin voit kirjautua sisään, sinun täytyy aktivoida uusi salasana linkistä, jonka lähetimme sähköpostiisi.");
                setInfo2("Ennen kuin voit kirjautua sisään, sinun täytyy aktivoida uusi salasana linkistä, jonka lähetimme sähköpostiisi.");
              }
            } 
            else if (data.status == "dateoff"){
              if(!finland){
              setInfo("Your credit has expired, and your account has been deactivated.");
              setInfo2("Your credit has expired, and your account has been deactivated.");
              }else {
                setInfo("Pakettisi on vanhentunut, ja tilisi on deaktivoitu.");
                setInfo2("Pakettisi on vanhentunut, ja tilisi on deaktivoitu.");
              }
            }            
            else {
              if(!finland){
              setInfo("Something went wrong, please contact Lassi.");
              setInfo2("Something went wrong, please contact Lassi.");
              }else {
                setInfo("Jotain meni pieleen, ole hyvä ja ota yhteyttä Lassiin.");
                setInfo2("Jotain meni pieleen, ole hyvä ja ota yhteyttä Lassiin.");
              }
            }
          }
        ) 
        .catch(error => console.error(error));  
       */
        
        setInfo2("Coming soon!");
      };

      //Google signin if needed
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            setEmail(user.email);
          } else {
            setEmail("");
          }
        });

        const domain = window.location.hostname;

        if (domain === 'theinterviewsimulator.com' || domain === 'www.theinterviewsimulator.com') {
          setFinland(false);
          setTitles(titlesEng);
        } else {
          setFinland(true);
          setTitles(titlesFi);
        }
      
        // Cleanup function, which removes the user state listener.
        return () => unsubscribe();
      }, []);
    
      
      useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            event.preventDefault();
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
        
    
        // Komponentin poistuessa, poista tapahtumankäsittelijä
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

      const handleClosee = () => {
        setInfo2(""); // Nollaa info2:n arvo
      };

      const myRef = useRef();

      useEffect(() => {
        myRef.current.focus();
      }, []);
  
    if (!isLoading && !forgot && info2 == ""){
    return (
      <div >
        <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding:"10px",
          
        }}
      >
        <img className="FormLogo" src={Logo} style={{height:"5vh", marginTop:"1vh"}}></img>
        <h1  className="FormTitle" style={{fontSize:"1.95em", marginBottom:"2vh"}}>The Interview Simulator</h1>
        <h3 style={{fontSize:"1.3em"}}>{isLoginForm ? titles.signin : titles.signin}</h3>
        <Row
          className="justify-content-md-center"
          style={{
            width: "100%",
            height: "55vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col>
            <Card className='Card' style={{ width: "100%", height: "32vh" }}>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  borderRadius:"0"
                }}
              >
                <Form >
                 
                <Form.Group>
                  <Form.Label htmlFor="email" style={{marginBottom:"2vh"}}>{titles.id}</Form.Label>
                  <Form.Control
                    id="email"
                    type="text"
                    ref={myRef}
                    placeholder={titles.id2}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  
                </Form.Group>
                  
                </Form>
                <Button
                    className='SignIn'
                    variant="primary"
                    type="submit"
                    
                    style={{marginTop:"0vh", width:"40%", marginRight:"auto", marginLeft:"auto"}}
                    onClick={(e)=> handleSend(e)}
                    >
                    {isLoginForm ? titles.signin : titles.signin}
                  
                </Button>
              </Card.Body>
            </Card>
            
            
            
            <div className='formInfo'>{info}</div>
          </Col>
          <p className="support">support@lassialbin.com</p>
        </Row>
       
      </Container>
      </div>
    )
    }
    
    else {
      return (

        <div style={{ textAlign: "center" }}>
          <img className="logopikku" src={Logopikku} alt="kuva" />
          {info2 == "" && (
            <div>
          <p>{titles.loading}</p>
          </div>
          )}
          {info2 !== "" && (
            <div>
              <p>{info2}</p>
              
              <Button
                    className='SignIn'
                    variant="primary"
                    type="submit"
                    style={{marginTop:"0vh", width:"40%", marginRight:"auto", marginLeft:"auto"}}
                    onClick={(e)=> handleClosee(e)}
                    >
                    {finland ? <p>Sulje</p> : <p>Close</p>}
                  
                </Button>
            </div>
          )}
        </div>
      );
    }
    ;}

export default AuthenticationForm2;
